<style>
  .extra {
    display: flex
  }
  .extra .img{
      flex: 0 0 80px;
      max-width: 80px;
    }
  .extra .content{
    padding-left: 8px;
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
  }
  .extra .content-body{
    display: flex;
  }
  .extra .content-body .options{
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
    display: flex;
  }
  .extra .content-body .options .option-price{
    flex: 0 0 100px;
    max-width:  100px;
    align-self: center;
    margin-left: 8px;
  }
  .extra .content-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }
  .extra .content-body .total{
    margin-left: auto;
    text-align: right;
    margin-top: -13px;
    line-height: 1.5;
  }
  .extra .content-body .total span{
    color: rgba(0, 0, 0, 0.6);
    font-size: 75%;
  }
</style>
<template>
  <div class="mt-2">
    <h3 class="mb-2">Seleccione Extras</h3>
    <div v-for="e in quote.availableExtras" :key="e.id">
      <ExtraAtv
        v-if="e.type == 'atv'"
        :extra="e"
        :rate="getRate()"
        :show-child="showChild"
        :pax="{
          adults: request.adults || 0,
          elderlies: request.elderlies || 0,
          children: request.children || 0,
          infants: request.infants || 0,
        }"
        :currency="request.currency"
        @quote-change-extra="quoteChangeExtra"
      />
      <ExtraRegular
        v-if="e.type == 'regular'"
        :extra="e"
        :rate="getRate()"
        :show-child="showChild"
        :pax="{
          adults: request.adults || 0,
          elderlies: request.elderlies || 0,
          children: request.children || 0,
          infants: request.infants || 0,
        }"
        :currency="request.currency"
        @quote-change-extra="quoteChangeExtra"
      />
    </div>
    <v-divider class="mt-2 mb-2"></v-divider>
  </div>
</template>
<script>
  import ExtraAtv from '@/components/quote/ExtraAtv'
  import ExtraRegular from '@/components/quote/ExtraRegular'
  export default {
    name:"QuoteExtras",
    components: { ExtraAtv, ExtraRegular },
    props:{
      quote: {
        type: Object,
      },
      request: {
        type: Object,
      },
      showChild: {
        default:true,
      }
    },
    methods: {
      getRate() {
        if (this.quote.couponNational) {
          return 'national';
        } else if (this.request.type == 'local') {
          return 'local';
        } else {
          return 'foreign';
        }
      },
      quoteChangeExtra(data) {
        this.$emit('quote-change-extra', {id: data})
      }
    }
  }
</script>