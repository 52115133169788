<style>
  .py-0{
    padding-top:0; 
    padding-bottom:0; 
  }
  .quote{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .m-0{
    margin:0 !important;
  }
  @media(min-width: 600px) {
    .quote {
      flex-direction: row;
    }
    .quote .img{
      flex: 0 0 200px;
      max-width: 200px;
    }
    .quote .content{
      padding-left: 8px;
      flex: 0 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
    }
  }
  .v-tabs-items{
    width: 100%;
  }
  .py-0{
    padding-bottom: 0;
    padding-top: 0;
  }
  .maz-phone-number-input .country-selector .maz-input{
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-left-radius: 0;
    border-color: rgba(0, 0, 0, 0.87);
  }
  .maz-phone-number-input .country-selector {
    flex: 0 0 7.857rem;
    width: 7.857rem;
    min-width: 7.857rem;
    max-width: 7.857rem;
  }
  .maz-phone-number-input .maz-input.input-phone-number:not(.has-border-radius){
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-right-radius: 0;
    border-color: rgba(0, 0, 0, 0.87);
  }
  .reservation-totals{
    position: fixed;
    width: calc(50% - 48px);
  }
</style>
<template>
  <div style="">
    <portal to="title-page">
      <h1>Ingresar nueva reservación</h1>
    </portal>
    <Loading :active="loading"/>
    <v-row>
      <v-col cols="12" md="6">
        <v-expansion-panels
          v-model="panel"
          multiple
          class="mx-auto elevation-1"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="v-card__title" style="padding: 0">Cotizador</h3>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <QuoteForm
                :request="request"
                :tours="tours"
                :currencies="currencies"
                :languages="languages"
                :zones="zones"
                @quote-init="quoteInit"
                @quote-success="quoteSuccess"
                @quote-error="quoteError"
                @quote-update-request="updateRequest"
                v-bind:rq.sync="request"
              ></QuoteForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels
          v-model="panelQuote"
          multiple
          class="mx-auto elevation-1 mt-2"
        >
          <v-expansion-panel v-if="quote">
            <v-expansion-panel-header>
              {{quote.title}}
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <div class="extras mt-2">
                <v-tabs
                  v-model="tab"
                  background-color="primary"
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows
                >
                  <v-tab>
                    Extras
                  </v-tab>
                  <v-tab>
                    Incluye
                  </v-tab>
                  <v-tab>
                    Importante
                  </v-tab>
                  <v-tab>
                    Recomendaciones
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-card
                      flat
                    >
                      <v-card-text>
                        <QuoteExtras :quote="quote" :request="request" @quote-change-extra="quoteChangeExtra"/>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                      flat
                    >
                      <v-card-text v-html="quote.include"></v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                      flat
                    >
                      <v-card-text v-html="quote.important"></v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                      flat
                    >
                      <v-card-text v-html="quote.recommendation"></v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6" v-if="quote">
        <div class="reservation-totals">
          <h2 class="mb-2">Totales</h2>
          <template v-if="selectedExtras.length">
            <h3>Extras</h3>
            <div v-for="(e, key) in selectedExtras" :key="key" >
              <h4 class="d-flex">
                <span>{{ e.name }}</span>
              </h4>
              <h4 v-if="e.type == 'regular' && e.quantityAdults > 0" class="d-flex pl-2">
                <span>Adultos {{ e.quantityAdults }} x ${{ e.priceAdult }}{{ request.currency }}</span>
                <span class="ml-auto">${{ e.quantityAdults * e.priceAdult }}{{ request.currency }}</span>
              </h4>
              <h4 v-if="e.type == 'regular' && e.quantityChildren > 0" class="d-flex pl-2">
                <span>Niños {{ e.quantityChildren }} x ${{ e.priceChild }}{{ request.currency }}</span>
                <span class="ml-auto">${{ e.quantityChildren * e.priceChild }}{{ request.currency }}</span>
              </h4>
              <h4 v-if="e.type == 'atv' && e.distribution.single > 0" class="d-flex pl-2">
                <span>Adultos en individuales {{ e.distribution.single }} x ${{ e.priceAdult }}{{ request.currency }}</span>
                <span class="ml-auto">${{ e.distribution.single * e.priceAdult}}{{ request.currency }}</span>
              </h4>
              <h4 v-if="e.type == 'atv'  && e.distribution.adultsShared > 0" class="d-flex pl-2">
                <span>Adultos en compartidas {{ e.distribution.adultsShared }} x ${{ e.priceAdultShared }}{{ request.currency }}</span>
                <span class="ml-auto">${{ e.distribution.adultsShared * e.priceAdultShared }}{{ request.currency }}</span>
              </h4>
              <h4 v-if="e.type == 'atv' && e.distribution.children > 0" class="d-flex pl-2">
                <span>Niños {{ e.distribution.children }} x {{ e.priceChild }}{{ request.currency }}</span>
                <span class="ml-auto">${{ e.distribution.children * e.priceChild }}{{ request.currency }}</span>
              </h4>
            </div>
          </template>
          <h4 class="d-flex">
            <span>{{ quote.name }}</span> <span class="ml-auto">${{numberFormat(quote.subtotal, 2, ',', '.')}}<small>{{request.currency}}</small></span>
          </h4>
          <h4 v-if="quote.couponNationalDiscount > 0" class="d-flex red--text">
            <span>Descuento Nacionales ({{numberFormat(parseFloat(quote.couponNationalDiscountPercent), 2, ',', '.')}}%)</span>
            <strong class='ml-auto'>-${{numberFormat(quote.couponNationalDiscount, 2, ',', '.')}}<small>{{request.currency}}</small></strong>
          </h4>
          <h4 v-if="quote.couponDiscount > 0" class="d-flex red--text">
            <span>Descuento ({{numberFormat(parseFloat(quote.couponDiscountPercent), 2, ',', '.')}}%)</span>
            <strong class='ml-auto'>-${{numberFormat(quote.couponDiscount, 2, ',', '.')}}<small>{{request.currency}}</small></strong>
          </h4>
          <v-divider></v-divider>
          <h3 class="border-top p-2 text-right">
            <span>Total</span><span class="ml-2">${{numberFormat(computedTotal, 2, ',', '.')}}<small>{{request.currency}}</small></span>
          </h3>
          <div>
            <h2 class="mb-2">Datos del cliente</h2>
            <v-form ref="formBook" v-model="validform">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="reservation.customer.name"
                    :rules="[v => !!v || 'Campo requerido']"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="reservation.customer.lastname"
                    :rules="[v => !!v || 'Campo requerido']"
                    label="Apellidos"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="reservation.customer.email"
                    label="Email"
                  ></v-text-field>
                    <!-- :rules="emailRules" -->
                </v-col>
                <v-col cols="12" sm="12">
                  <MazPhoneNumberInput
                    v-model="reservation.customer.phone"
                    @update="updateCountry"
                    noExample
                    placeholder="Telefono"
                    hint="Telefono"
                  />
                  <!-- <v-text-field
                    v-model="reservation.customer.phone"
                    label="Tel"
                  ></v-text-field> -->
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-btn
            class="mt-md-3"
            small
            elevation="2"
            color="primary"
            @click="book()"
          >
            Ingresar reservación
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Vue from "vue";
  import Loading from "@/components/Loading";
  import QuoteForm from "@/components/quote/Form";
  import QuoteExtras from "@/components/quote/Extras";
  import firebase from 'firebase/app';
  import 'firebase/auth';
  const axios = require('axios');

  import { MazPhoneNumberInput } from 'maz-ui'

  Vue.use(MazPhoneNumberInput)
  Vue.use('Loading', Loading);
  //Vue.component('History', History);
  export default {
    name:'TaquillaReservationAdd',
    components:{ QuoteForm, QuoteExtras },
    data() {
      let date = this.$moment()
      const request = {
          tours:[],
          date:date.format('YYYY-MM-DD'),
          type:'foreign',
          //includeUrl:true,
          includeMainImage:true,
          includeAvailableExtras:1,
          adults:2,
          elderlies:0,
          children:0,
          infants:0,
          lang:'es',
          currency:'mxn',
          coupon:'',
          couponNational:'',
          transportation:{
            zone:null,
            type:null,
          }
        }
      return {
        panel:[0],
        panelQuote:[0],
        tours:[],
        currencies:[],
        languages:[],
        zones:[],
        //mindate:date.format('YYYY-MM-DD'),
        request:request,
        totalExtras:0,
        tab:0,
        quote:null,
        unsubscribe:null,
        loading:true,
        validform:true,
        countryData:null,
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        reservation:{
          customer:{name:'',lastname:'',email:'',phone:''},
          //hotel:'',
          //room:'',
          //pickuptime:'',
          //paymentMethod:'paypal',
          //rqData:null,
        },
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
        ],
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Taquilla')
    },
    mounted() {
      firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        var config = {
          method: 'get',
          url: process.env.VUE_APP_API_URL+'/api/data-quote-panel?lang=es',
          //data: {uid:this.uid, password: this.password},
          headers:{
            'token':idToken
          }
        };
        axios(config)
        .then(async (data) => {
          this.loading = false
          this.tours = data.data.tours
          this.currencies = data.data.currencies
          this.languages = data.data.languages
          this.zones = data.data.zones
        })
        .catch((error) => {
          console.log(error);
          this.loading = false
          if (error.response && error.response.data && error.response.data.error) {
            this.showSnackbarError(error.response.data.error.message)
          } else {
            this.showSnackbarError('Error al obtener los datos del cotizador')
          }
        });
      }).catch((error) => {
        // Handle error
        console.log('error al obtener el token', error);
        this.loading = false
        this.showSnackbarError('Error al obtener los datos del cotizador')
      });
      // this.unsubscribe = 
    },
    methods:{
      updateCountry(e) {
        this.countryData = e
      },
      book() {
        if (this.$refs.formBook.validate()) {
          this.loading = true
          firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            let rq = {...this.request}
            rq.adults = rq.adults + ''
            rq.elderlies = rq.elderlies + ''
            rq.children = rq.children + ''
            rq.infants = rq.infants + ''
            let customer = this.customer
            customer.email = customer.email.trim()
            var config = {
              method: 'post',
              url: process.env.VUE_APP_API_URL+'/api/reservation-panel',
              data: {
                data: {
                  ...this.reservation,
                  ...this.request,
                  extras:this.selectedExtras,
                  customer:customer,
                }
              },
              headers:{
                'token':idToken
              }
            };
            axios(config)
            .then(async (data) => {
              this.$router.push(
                {path:`/backend/taquilla/reservations/${data.data.id}/edit`},
                () => {
                  this.loading = false
                }
              )
            })
            .catch((error) => {
              console.log(error);
              this.loading = false
              if (error.response && error.response.data && error.response.data.error) {
                this.showSnackbarError(error.response.data.error.message)
              } else {
                this.showSnackbarError('Error al crear la reservación')
              }
            });
          }).catch((error) => {
            // Handle error
            console.log('error al obtener el token', error);
            this.loading = false
            this.showSnackbarError('Error al crear la reservación')
          });
        }
      },
      numberFormat(number, decimals, thousandSeparator, decimalSeparator) {
        var re = '\\d(?=(\\d{' + (3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')',
            num = number.toFixed(Math.max(0, ~~decimals));

        return (decimalSeparator ? num.replace('.', decimalSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (thousandSeparator || ','));
      },
      quoteChangeExtra() {
        this.totalExtras = 0;
        for (var i in this.quote.availableExtras) {
          if (this.quote.availableExtras[i].type === 'atv' && this.quote.availableExtras[i].distribution) {
            this.totalExtras += this.quote.availableExtras[i].distribution.total;
          } else {
            if (this.quote.availableExtras[i].quantityAdults > 0) {
              this.totalExtras += (this.quote.availableExtras[i].quantityAdults * this.getExtraPriceAdult(this.quote.availableExtras[i]));
            }
            if (this.quote.availableExtras[i].quantityChildren > 0) {
              this.totalExtras += (this.quote.availableExtras[i].quantityChildren * this.getExtraPriceChild(this.quote.availableExtras[i]));
            }
          }
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      quoteInit() {
        this.loading = true
      },
      quoteSuccess(quote) {
        this.quote = quote
        this.loading = false
        this.panel.splice(0, 1)
      },
      quoteError(error) {
        this.loading = false
        if (error.response && error.response.data && error.response.data.error) {
          this.showSnackbarError(error.response.data.error.message)
        } else {
          this.showSnackbarError('Error al cotizar')
        }
      },
      updateRequest(rq) {
        this.request = {...rq}
      },
      getExtraPriceAdult(extra) {
        let price = extra.rate.foreignAdult;
        if (this.rateExtra == 'national') {
          price = extra.rate.nationalAdult;
        } else if (this.rateExtra == 'local') {
          price = extra.rate.localAdult;
        }
        return price;
      },
      getExtraPriceAdultShared(extra) {
        let price = extra.rate.foreignAdultShared;
        if (this.rateExtra == 'national') {
          price = extra.rate.nationalAdultShared;
        } else if (this.rateExtra == 'local') {
          price = extra.rate.localAdultShared;
        }
        return price;
      },
      getExtraPriceChild(extra) {
        let price = extra.rate.foreignChild;
        if (this.rateExtra == 'national') {
          price = extra.rate.nationalChild;
        } else if (this.rateExtra == 'local') {
          price = extra.rate.localChild;
        }
        return price;
      }
    },
    computed: {
      rateExtra() {
        if (this.quote.couponNational) {
          return 'national';
        } else if (this.request.type == 'local') {
          return 'local';
        } else {
          return 'foreign';
        }
      },
      computedTotal() {
        return this.quote.total + this.totalExtras
      },
      customer() {
        return {
          countryCallingCode:this.countryData.countryCallingCode || '',
          countryCode:this.countryData.countryCode || '',
          formatInternational:this.countryData.formatInternational || '',
          formatNational:this.countryData.formatNational || '',
          nationalNumber:this.countryData.nationalNumber || '',
          ...this.reservation.customer
        }
      },
      selectedExtras() {
        var selectedEtras = [];
        for (var i in this.quote.availableExtras) {
          if (this.quote.availableExtras[i].type === 'atv' && this.quote.availableExtras[i].distribution) {
            selectedEtras.push({
              id: this.quote.availableExtras[i].id,
              name: this.quote.availableExtras[i].name,
              type: 'atv',
              distribution: this.quote.availableExtras[i].distribution,
              quantityAdults: this.quote.availableExtras[i].distribution.single + (this.quote.availableExtras[i].distribution.shared * 2),
              quantityChildren: this.quote.availableExtras[i].distribution.children,
              priceAdult: this.getExtraPriceAdult(this.quote.availableExtras[i]),
              priceAdultShared: this.getExtraPriceAdultShared(this.quote.availableExtras[i]),
              priceChild: this.getExtraPriceChild(this.quote.availableExtras[i]),
            })
          } else {
            if (this.quote.availableExtras[i].quantityAdults > 0 || this.quote.availableExtras[i].quantityChildren > 0) {
              selectedEtras.push({
                id: this.quote.availableExtras[i].id,
                name: this.quote.availableExtras[i].name,
                type: 'regular',
                quantityAdults: this.quote.availableExtras[i].quantityAdults,
                quantityChildren: this.quote.availableExtras[i].quantityChildren,
                priceAdult: this.getExtraPriceAdult(this.quote.availableExtras[i]),
                priceChild: this.getExtraPriceChild(this.quote.availableExtras[i]),
              })
            }
          }
        }
        return selectedEtras;
      },
    }
  }
</script>