<template>
  <div class="extra">
    <div class="img">
      <img style="width: 100%" v-if="extra.images[0]" :src="extra.images[0].url">
    </div>
    <div class="content">
      <h4 class="content-title">{{extra.name}}</h4>
      <div class="content-body">
        <div class="options">
          <v-select
            v-model="extra.quantityAdults"
            :items="optionsExtras"
            :label="showChild ? `Adultos` : ``"
            persistent-hint
            item-text="text"
            item-value="value"
            dense
            @change="$emit('quote-change-extra', {id: extra.id})"
          ></v-select>
          <span class="option-price"> x {{ priceAdult }}{{ currency }}</span>
        </div>
        <div class="total">
          <span>Total</span>
          <div>${{numberFormat(priceAdult * extra.quantityAdults, 2, ',', '.')}}{{ currency }}</div>
        </div>
      </div>  
      <div v-if="showChild" class="content-body">
        <div class="options">
          <v-select
            v-model="extra.quantityChildren"
            :items="optionsExtras"
            label="Niños"
            persistent-hint
            item-text="text"
            item-value="value"
            dense
            @change="$emit('quote-change-extra', {id: extra.id})"
          ></v-select>
          <span class="option-price"> x {{ priceChild }}{{ currency }}</span>
        </div>
        <div class="total">
          <span>Total</span>
          <div>${{numberFormat(priceChild * extra.quantityChildren, 2, ',', '.')}}{{ currency }}</div>
        </div>
      </div>    
    </div>
  </div>
</template>
<script>
  export default {
    name: "ExtraRegular",
    props:['extra', 'rate', 'currency', 'showChild'],
    data() {
      var optionsExtras = [];
      for (var i = 0; i <31; i++) {
        optionsExtras.push({value:i,text:i})
      }
      return {
        optionsExtras
      }
    },
    computed: {
      priceAdult() {
        if (this.rate == 'local') {
          return this.extra.rate.localAdult;
        } else if (this.rate == 'national') {
          return this.extra.rate.nationalAdult;
        } else  {
          return this.extra.rate.foreignAdult;
        }
      },
      priceChild() {
        if (this.rate == 'local') {
          return this.extra.rate.localChild;
        } else if (this.rate == 'national') {
          return this.extra.rate.nationalChild;
        } else  {
          return this.extra.rate.foreignChild;
        }
      }
    },
    methods:{
      numberFormat(number, decimals, thousandSeparator, decimalSeparator) {
        var re = '\\d(?=(\\d{' + (3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')',
            num = number.toFixed(Math.max(0, ~~decimals));

        return (decimalSeparator ? num.replace('.', decimalSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (thousandSeparator || ','));
      },
    }
  }
</script>