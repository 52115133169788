<template>
   <v-form
    ref="form"
    v-model="validForm"
  >
      <v-row >
        <v-col cols="12" sm="12" md="4" class="py-0">
          <v-select
            v-model="requestQuote.type"
            :items="rateTypes"
            label="Tarifa"
            persistent-hint
            item-text="name"
            item-value="id"
            prepend-icon="mdi-check-circle"
            @change="requestQuote.tours = [selectedTour]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-select
            v-model="requestQuote.currency"
            :items="currencies"
            label="Moneda"
            persistent-hint
            item-text="name"
            item-value="id"
            prepend-icon="mdi-currency-usd"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-select
            v-model="requestQuote.lang"
            :items="languages"
            label="Lenguaje"
            persistent-hint
            item-text="name"
            item-value="id"
            prepend-icon="mdi-flag"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="7" md="6" class="py-0">
          <v-select
            v-model="selectedTour"
            :items="tours"
            label="Tour"
            persistent-hint
            item-text="name"
            item-value="id"
            prepend-icon="mdi-format-list-bulleted"
            :rules="[v => !!v || 'Seleccione un tour']"
            @change="requestQuote.tours = [selectedTour]"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="6" class="py-0">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="requestQuote.date"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="requestQuote.date"
              :min="mindate"
              @input="menuDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-select
            v-model="requestQuote.adults"
            :items="optionsPax"
            label="Adultos"
            persistent-hint
            prepend-icon="mdi-human-male"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-select
            v-model="requestQuote.elderlies"
            :items="optionsPax"
            label="A.mayor"
            persistent-hint
            :prepend-icon="mdiHumanCane"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-select
            v-model="requestQuote.children"
            :items="optionsPax"
            label="Niños"
            persistent-hint
            prepend-icon="mdi-human-male-child"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-select
            v-model="requestQuote.infants"
            :items="optionsPax"
            label="Infantes"
            persistent-hint
            prepend-icon="mdi-baby-carriage"
          ></v-select>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="3" class="py-0">
          <v-checkbox
            v-model="transportation"
            label="Transporte"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" class="py-0" v-if="transportation">
          <v-select
            v-model="requestQuote.transportation.zoneId"
            :items="zones"
            label="Zona"
            persistent-hint
            prepend-icon="mdi-map-marker"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
      </v-row> -->
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="4" class="py-0">
          <v-checkbox
            v-model="requestQuote.couponNational"
            value="mxzapote"
            label="Nacional"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="3" class="py-0">
          <v-checkbox
            v-model="coupon"
            label="Cupon"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="5" class="py-0" v-if="coupon">
          <v-text-field
            v-model="requestQuote.coupon"
            label="Cupon"
            prepend-icon="mdi-ticket-percent"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="4" class="py-0" v-if="requestQuote.couponNational">
          <v-text-field
            v-model="requestQuote.couponNational"
            label="Cupon para nacionales"
            prepend-icon="mdi-ticket-percent"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-btn
        class="mt-md-3"
        small
        elevation="2"
        color="primary"
        @click="getQuote()"
      >
        Cotizar
      </v-btn>
   </v-form> 
</template>
<script>
  import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
  import firebase from 'firebase/app';
  import 'firebase/auth';
  const axios = require('axios');
  export default {
    name: 'QuoteForm',
    props: ['request', 'tours', 'currencies', 'languages', 'zones'],
    data() {
      let optionsPax = []
      for (var i = 0; i < 21; i++) {
        optionsPax.push(i)
      }
      return {
        mdiHumanCane:mdiHumanCane,
        optionsPax,
        mindate:this.request.date,
        requestQuote: {panel:'taquilla', ...this.request},
        rateTypes:['foreign', 'local'],
        selectedTour:null,
        menuDate:false,
        transportation:false,
        coupon:false,
        validForm:true,
      }
    },
    methods: {
      getQuote() {
        //this.loading = true
        if (this.$refs.form.validate()) {
          this.$emit('quote-init')
          firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            var config = {
              method: 'post',
              url: process.env.VUE_APP_API_URL+'/api/quote-single-tour-panel',
              data: this.requestQuote,
              headers:{
                'token':idToken
              }
            };
            axios(config)
            .then(async (data) => {
              this.$emit('update:rq', {...this.requestQuote})
              // this.$emit('quote-update-request', this.requestQuote)
              this.$emit('quote-success', data.data)
              //this.quote = data.data
              //this.panel.splice(0,1)
              //console.log('data', data);
            })
            .catch((error) => {
              console.log(error);
              this.$emit('quote-error', error)
              /*this.loading = false
              if (error.response && error.response.data && error.response.data.error) {
                this.showSnackbarError(error.response.data.error.message)
              } else {
                this.showSnackbarError('Error al cotizar')
              }*/
            });
          }).catch((error) => {
            // Handle error
            console.log('error al obtener el token', error);
            this.$emit('quote-error', error)
            /*this.myLoading.isLoading = false
            this.showSnackbarError('Error al cotizar')*/
          });
        }
      },
    }
  }
</script>