<template>
  <div class="extra">
    <div class="img">
      <img style="width: 100%" v-if="extra.images[0]" :src="extra.images[0].url">
    </div>
    <div class="content">
      <h4 class="content-title">{{extra.name}}</h4>
      <div class="content-body">
        <div class="options">
          <v-select
            v-model="extra.distribution"
            :items="distributions"
            label="Seleccione cuatrimotos"
            persistent-hint
            item-text="text"
            item-value="value"
            dense
            @change="$emit('quote-change-extra', {id: extra.id})"
          ></v-select>
        </div>
        <div class="total">
          <span>Total</span>
          <div>${{ total }}{{ currency }} </div>
        </div>
      </div>    
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ExtraAtv',
    props:['extra', 'pax', 'currency', 'rate'],
    data() {
      let error = null
      let adults = parseInt(this.pax.adults) + parseInt(this.pax.elderlies)
      const children = parseInt(this.pax.children) + parseInt(this.pax.infants)
      let distributions = []

      let adultsWithChildren = children
      let adultsAtvs = adults - adultsWithChildren
      let shared = Math.floor(adultsAtvs / 2)
      let single = adultsAtvs % 2

      const defaultDistribution = {
        text: this.getText(single, Math.floor((adults + children) / 2), children),
        value: {
          single: single,
          shared: shared,
          adultsShared: (shared * 2) + adultsWithChildren,
          children: children,
          total: this.getTotalDistribution(single, (shared * 2) + adultsWithChildren, children)
        }
      }
      distributions.push({
        text: 'Seleccione una opción',
        value: null
      })
      if (adults >= children) {
        distributions.push(defaultDistribution)
        const baseDistribution = {...defaultDistribution.value}
        let adultsShared
        while(baseDistribution.shared + children > baseDistribution.children) {
          baseDistribution.shared -= 1
          baseDistribution.single += 2
          adultsShared = (baseDistribution.shared * 2) + adultsWithChildren
          distributions.push({
            text: this.getText(baseDistribution.single, baseDistribution.shared + children, children),
            value: {
              single: baseDistribution.single,
              shared: adultsShared == 1 ? 1 : baseDistribution.shared,
              adultsShared: adultsShared,
              children: children,
              total: this.getTotalDistribution(baseDistribution.single, (baseDistribution.shared * 2) + adultsWithChildren, children)
            }
          })
        }
      } else {
        error = {
          code: 1,
          message: 'No permitido', 
        }
      }
      return {
        error,
        distributions,
        flags:{
          es:'mx',
          en:'us'
        }
      }
    },
    computed: {
      total() {
        if (this.extra.distribution) {
          return this.extra.distribution.total
        }
        return 0
      },
    },
    methods:{
      numberFormat(number, decimals, thousandSeparator, decimalSeparator) {
        var re = '\\d(?=(\\d{' + (3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')',
            num = number.toFixed(Math.max(0, ~~decimals));

        return (decimalSeparator ? num.replace('.', decimalSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (thousandSeparator || ','));
      },
      getTotalDistribution(single, shared, children) {
        return (this.priceAdult() * single)+(this.priceAdultShared() * shared)+(this.priceChild() * children)
      },
      priceAdult() {
        if (this.rate == 'local') {
          return this.extra.rate.localAdult;
        } else if (this.rate == 'national') {
          return this.extra.rate.nationalAdult;
        } else  {
          return this.extra.rate.foreignAdult;
        }
      },
      priceAdultShared() {
        if (this.rate == 'local') {
          return this.extra.rate.localAdultShared;
        } else if (this.rate == 'national') {
          return this.extra.rate.nationalAdultShared;
        } else  {
          return this.extra.rate.foreignAdultShared;
        }
      },
      priceChild() {
        if (this.rate == 'local') {
          return this.extra.rate.localChild;
        } else if (this.rate == 'national') {
          return this.extra.rate.nationalChild;
        } else  {
          return this.extra.rate.foreignChild;
        }
      },
      getText(single, shared, children) {
        let text = ''
        if (single > 0) {
          if (single == 1) {
            text += single + ' Cuatrimoto Individual'
          } else {
            text += single + ' Cuatrimotos Individuales'
          }
        }
        if (shared > 0) {
          if (text.length) {
            text += ' & '
          }
          if (shared == 1) {
            text += shared + ' Cuatrimoto Compartida'
          } else {
            text += shared + ' Cuatrimotos Compartidas'
          }
        }
        if (children > 0) {
          text += ` con `
          if (children == 1) {
            text += children + ' Niño'
          } else {
            text += children + ' Niños'
          }
        }
        return text
      },
    }
  }
</script>