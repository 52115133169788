var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Seleccione Extras")]),_vm._l((_vm.quote.availableExtras),function(e){return _c('div',{key:e.id},[(e.type == 'atv')?_c('ExtraAtv',{attrs:{"extra":e,"rate":_vm.getRate(),"show-child":_vm.showChild,"pax":{
        adults: _vm.request.adults || 0,
        elderlies: _vm.request.elderlies || 0,
        children: _vm.request.children || 0,
        infants: _vm.request.infants || 0,
      },"currency":_vm.request.currency},on:{"quote-change-extra":_vm.quoteChangeExtra}}):_vm._e(),(e.type == 'regular')?_c('ExtraRegular',{attrs:{"extra":e,"rate":_vm.getRate(),"show-child":_vm.showChild,"pax":{
        adults: _vm.request.adults || 0,
        elderlies: _vm.request.elderlies || 0,
        children: _vm.request.children || 0,
        infants: _vm.request.infants || 0,
      },"currency":_vm.request.currency},on:{"quote-change-extra":_vm.quoteChangeExtra}}):_vm._e()],1)}),_c('v-divider',{staticClass:"mt-2 mb-2"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }